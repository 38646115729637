import { FrontierClubLogo } from "../content/contentExport";

const events = [
  {
    id: 1,
    title: "6202 Rocks the Sports Lounge Off Broadway",
    date: "Fri, Mar 14, 2025",
    time: "8:00pm - 12:00am",
    location: "Sports Lounge Off Broadway",
    address: "44 W Centennial Blvd, Highlands Ranch, CO 80129, USA",
    description:
      "6202 takes over Sports Lounge off Broadway for a night of great tunes and good vibes. Don’t miss the fun from 8:30 PM to 12:30 AM!",
    logo: "https://images.getbento.com/accounts/fb53991197d2d7039b97c870007667e1/media/images/92007SLOB-White-trans-1-01.png",
    link: "https://www.sportsloungebar.com/",
    mapEmbed:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3075.8978589237086!2d-104.98825869999999!3d39.5619106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c82337973cfdf%3A0xe9af81e73250c12!2sSports%20Lounge%20Off%20Broadway!5e0!3m2!1sen!2sus!4v1739491385670!5m2!1sen!2sus",
  },
  {
    id: 2,
    title: "6202 Brings the Hype to Hoffbrau!",
    date: "Thurs, Mar 20, 2025",
    time: "8:30pm - 11:30pm",
    location: "Hoffbrau Bar & Grill",
    address: "9110 Wadsworth Pkwy, Westminster, CO 80021, USA",
    description: "Come kick off spring at Hoffbrau Bar & Grill for a 6202 Thursday night special.",
    logo: "https://www.hoffbrau.com/sites/all/themes/bestmobile/logo.png",
    link: "https://www.hoffbrau.com/",
    mapEmbed:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4331.127240933925!2d-105.08243352872017!3d39.861480918289885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c78cd4a7a2535%3A0xc28a7f4087eb002d!2sHoffbrau!5e0!3m2!1sen!2sus!4v1725456794747!5m2!1sen!2sus",
  },
  {
    id: 3,
    title: "Spring Rockin' at Hoffbrau",
    date: "Thurs, May 29, 2025",
    time: "8:30pm - 11:30pm",
    location: "Hoffbrau Bar & Grill",
    address: "9110 Wadsworth Pkwy, Westminster, CO 80021, USA",
    description:
      "As the weather warms, we’ll be turning up the heat with rock hits from every era at Hoffbrau! Perfect for your Thursday night out.",
    logo: "https://www.hoffbrau.com/sites/all/themes/bestmobile/logo.png",
    link: "https://www.hoffbrau.com/",
    mapEmbed:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4331.127240933925!2d-105.08243352872017!3d39.861480918289885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c78cd4a7a2535%3A0xc28a7f4087eb002d!2sHoffbrau!5e0!3m2!1sen!2sus!4v1725456794747!5m2!1sen!2sus",
  },
  {
    id: 4,
    title: "Rock Out at Palmer Lake Pub",
    date: "Sat, Jun 7, 2025",
    time: "4:00pm - 7:30pm",
    location: "Palmer Lake Pub",
    address: "876 CO-105, Palmer Lake, CO 80133, USA",
    description:
      "Enjoy a sunny Saturday afternoon of rock classics and modern hits at Palmer Lake Pub! It’s the perfect setting for some fun.",
    logo: "https://static.wixstatic.com/media/e903ec_907eba549830449a9eb9603790844e96~mv2.jpg/v1/crop/x_2,y_0,w_957,h_838/fill/w_317,h_274,al_c,q_80,usm_0.66_1.00_0.01,enc_avif,quality_auto/PLP_Logo_White.jpg",
    link: "https://www.palmerlakepub.net/",
    mapEmbed:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d198658.7515448148!2d-104.91836088769759!3d38.92299481431714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876cad0616815a73%3A0x1c8a413e97c2c0fd!2sPalmer%20Lake%20Pub!5e0!3m2!1sen!2sus!4v1737930499877!5m2!1sen!2sus",
  },
  {
    id: 5,
    title: "6202 Rocks the House at Takoda Tavern",
    date: "Fri, Jun 27, 2025",
    time: "7:30pm - 11:30pm",
    location: "Takoda Tavern",
    address: "12365 Pine Bluffs Way, Parker, CO 80134, USA",
    description:
      "Kick off the weekend with the best rock hits from the ‘60s to today! Join us at Takoda Tavern for a high-energy night.",
    logo: "http://nebula.wsimg.com/61ee8aaed0b74e6676d11f86c8ae73eb?AccessKeyId=F95A2FBD5F21156E5012&disposition=0&alloworigin=1",
    link: "http://www.takodatavern.com/",
    mapEmbed:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3079.008369609031!2d-104.75976522346205!3d39.49172561141725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c91865e8c9181%3A0x4b6ef77cfb153c42!2sTakoda%20Tavern!5e0!3m2!1sen!2sus!4v1713718792379!5m2!1sen!2sus",
  },
  {
    id: 6,
    title: "6202 Returns to Wrigley's for a Saturday Night Special",
    date: "Sat, Jul 12, 2025",
    time: "4:00pm - 8:00pm",
    location: "Wrigley's Chicago Bar & Grill",
    address: "18200 W Colfax Ave, Golden, CO 80401, USA",
    description: "6202 is back at Wrigley's for a Summer show on the outdoor stage!",
    logo: "https://www.wrigleyschicagobar.net/wp-content/themes/wrigleys/images/wrigleys-chicago-bar-grill-logo.png",
    link: "https://www.wrigleyschicagobar.net/",
    mapEmbed:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3068.935269867049!2d-105.2077428234542!3d39.718637397830946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876b84a526ece61f%3A0xe7a24f1d4def9111!2sWrigley&#39;s%20Chicago%20Bar%20%26%20Grill!5e0!3m2!1sen!2sus!4v1720399962184!5m2!1sen!2sus",
  },
  {
    id: 7,
    title: "6202 Live at Sheabeen Irish Pub",
    date: "Fri, Jul 25, 202",
    time: "8:00pm - 12:00am",
    location: "Sheabeen Irish Pub",
    address: "2300 S Chambers Rd # A, Aurora, CO 80014, USA",
    description:
      "Join 6202 for another unforgettable night of live music at Sheabeen Irish Pub! Come for the great tunes, drinks, and lively atmosphere",
    logo: "https://www.sheabeenirishpub.com/images/logo2.jpg",
    link: "https://www.sheabeenirishpub.com/",
    mapEmbed:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3070.9045608600677!2d-104.80991162345566!3d39.67436140048686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c882f44a351eb%3A0xc41b87662b374cb9!2sSheabeens%20Irish%20Pub!5e0!3m2!1sen!2sus!4v1713718050501!5m2!1sen!2sus",
  },
  {
    id: 8,
    title: "Summer Rock Vibes at Palmer Lake Pub",
    date: "Sat, Aug 16, 2025",
    time: "4:00pm - 7:30pm",
    location: "Palmer Lake Pub",
    address: "876 CO-105, Palmer Lake, CO 80133, USA",
    description:
      "Rock out this summer at Palmer Lake Pub with all your favorite songs! It’s the perfect mix of good tunes and good vibes.",
    logo: "https://static.wixstatic.com/media/e903ec_907eba549830449a9eb9603790844e96~mv2.jpg/v1/crop/x_2,y_0,w_957,h_838/fill/w_317,h_274,al_c,q_80,usm_0.66_1.00_0.01,enc_avif,quality_auto/PLP_Logo_White.jpg",
    link: "https://www.palmerlakepub.net/",
    mapEmbed:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d198658.7515448148!2d-104.91836088769759!3d38.92299481431714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876cad0616815a73%3A0x1c8a413e97c2c0fd!2sPalmer%20Lake%20Pub!5e0!3m2!1sen!2sus!4v1737930499877!5m2!1sen!2sus",
  },

  {
    id: 9,
    title: "6202 Live at Frontier Club – Summer Night Special",
    date: "Sat, Aug 23, 2025",
    time: "8:00pm - 12:00am",
    location: "The Frontier Club",
    address: "18881 E Colfax Ave #4, Aurora, CO 80011, USA",
    description:
      "6202 is back at Frontier Club for a high-energy summer night of live music, cold drinks, and great company. Don’t miss out!",
    logo: FrontierClubLogo,
    link: "https://www.thefrontierclubaurora.com/",
    mapEmbed:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29193.73561161145!2d-104.76297589026187!3d39.72586896213633!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c63c40dc2af9d%3A0x294d72ce21dd2ae2!2sThe%20Frontier%20Club!5e0!3m2!1sen!2sus!4v1716481108592!5m2!1sen!2sus",
  },
  {
    id: 10,
    title: "6202 Returns to Sheabeen Irish Pub",
    date: "Sat, Sep 25, 2025",
    time: "9:00pm - 12:00am",
    location: "Sheabeen Irish Pub",
    address: "2300 S Chambers Rd # A, Aurora, CO 80014, USA",
    description:
      "6202 is back at Sheabeen Irish Pub for another memorable evening of live music! Come enjoy the good vibes, great drinks, and even better tunes.",
    logo: "https://www.sheabeenirishpub.com/images/logo2.jpg",
    link: "https://www.sheabeenirishpub.com/",
    mapEmbed:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3070.9045608600677!2d-104.80991162345566!3d39.67436140048686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c882f44a351eb%3A0xc41b87662b374cb9!2sSheabeens%20Irish%20Pub!5e0!3m2!1sen!2sus!4v1713718050501!5m2!1sen!2sus",
  },
  {
    id: 11,
    title: "Fall Rock Frenzy at Greenfields",
    date: "Fri, Oct 3, 2025",
    time: "8:00pm - 12:00am",
    location: "Greenfield's",
    address: "3355 S Yarrow St E101, Lakewood, CO 80227, USA",
    description:
      "Get ready for a fall rock frenzy at Greenfields! We’re bringing the classic hits and modern rock sounds for an unforgettable night.",
    logo: "https://img1.wsimg.com/isteam/ip/0ca600d5-a580-40b5-ad95-b7c4b4f7b18f/4442d3a6-6c6a-47fb-a48b-fe73880694f4.png/:/rs=w:159,h:106,cg:true,m/cr=w:159,h:106/qt=q:100/ll",
    link: "https://greenfieldsbar.com/",
    mapEmbed:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6143.56885764504!2d-105.08625812345643!3d39.654565801673684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876b81d20db6f029%3A0x57debcf670f6b798!2sGreenfields%20Pool%20%26%20Sports%20Bar!5e0!3m2!1sen!2sus!4v1713718305493!5m2!1sen!2sus",
  },
  {
    id: 12,
    title: "6202 Takes Over Wrigley’s for a Fall Show",
    date: "Sun, Oct 12, 2025",
    time: "3:00pm - 7:00pm",
    location: "Wrigley's Chicago Bar & Grill",
    address: "18200 W Colfax Ave, Golden, CO 80401, USA",
    description:
      "Cooler weather, same great energy! 6202 is back at Wrigley’s for a night of live music, good company, and great vibes. Don’t miss it!",
    logo: "https://www.wrigleyschicagobar.net/wp-content/themes/wrigleys/images/wrigleys-chicago-bar-grill-logo.png",
    link: "https://www.wrigleyschicagobar.net/",
    mapEmbed:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3068.935269867049!2d-105.2077428234542!3d39.718637397830946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876b84a526ece61f%3A0xe7a24f1d4def9111!2sWrigley&#39;s%20Chicago%20Bar%20%26%20Grill!5e0!3m2!1sen!2sus!4v1720399962184!5m2!1sen!2sus",
  },
];

export default events;
